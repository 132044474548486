import React from 'react';
import './ImportResultModal.css';

const ImportResultModal = ({ isOpen, onClose, results }) => {
  if (!isOpen || !results) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>×</button>
        <h2>Résultats de l'importation</h2>
        <p>Fichier importé: {results.filename}</p>
        <p>Nombre de leads importés: {results.imported_count}</p>
        <p>Nombre de leads déjà existants: {results.already_existing_count}</p>
        <button className="btn btn-primary" onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

export default ImportResultModal;