import React from 'react';
import ModernLeadDashboard from './components/ModernLeadDashboard';

function App() {
  // Determine the API URL based on the environment
  const apiUrl = window.location.hostname === 'localhost'
    ? 'http://localhost:5000'
    : `${window.location.origin}/api`;

  return (
    <div className="App">
      <ModernLeadDashboard apiUrl={apiUrl} />
    </div>
  );
}

export default App;
