import React, { useState } from 'react';
import './StatsTab.css'; // N'oubliez pas de créer ce fichier CSS

const StatCard = ({ title, value, icon, categories, showCategories, additionalInfo }) => (
  <div className={`stat-card ${categories ? 'hoverable' : ''}`}>
    <div className="stat-icon">
      {icon.startsWith('http') ? (
        <img src={icon} alt={title} style={{ width: '24px', height: '24px' }} />
      ) : (
        <span>{icon}</span>
      )}
    </div>
    <div className="stat-content">
      <h3>{title}</h3>
      <p className="stat-value">
        {value}
        {additionalInfo && <span className="additional-info"> ({additionalInfo})</span>}
      </p>
      {categories && <span className="hover-info">Survolez pour voir les catégories</span>}
    </div>
    {showCategories && categories && (
      <div className="categories-dropdown">
        <h4>Catégories :</h4>
        <ul>
          {Object.entries(categories).map(([category, count]) => (
            <li key={category}>{category}: {count}</li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

const StatSection = ({ title, children }) => (
  <div className="stat-section">
    <h2>{title}</h2>
    <div className="stat-grid">{children}</div>
  </div>
);

const StatsTab = ({ stats, tools }) => {
  const [hoveredToolAvailable, setHoveredToolAvailable] = useState(null);
  const [hoveredToolContacted, setHoveredToolContacted] = useState(null);
  const [hoveredToolCampaigns, setHoveredToolCampaigns] = useState(null);

  const totalLeads = stats.total_leads || 0;
  const leadsContactedToday = stats.exported_today || 0;
  const activeCampaigns = stats.unique_campaigns_today || 0;
  const leadsAvailable = stats.leads_available || {};
  const totalAvailable = stats.total_available || 0;
  const categoriesAvailable = stats.categories_available || {};
  const leadsContactedByTool = stats.leads_contacted_by_tool || {};
  const leadsContactedCategoriesByTool = stats.leads_contacted_categories_by_tool || {};
  const campaignsCreatedByTool = stats.campaigns_created_by_tool || {};
  const campaignsDetailsByTool = stats.campaigns_details_by_tool || {};

  // Calculer le nombre de leads uniques disponibles
  const uniqueAvailableLeads = Object.keys(
    Object.values(categoriesAvailable).reduce((acc, toolCategories) => {
      Object.entries(toolCategories).forEach(([category, count]) => {
        if (!acc[category]) acc[category] = 0;
        acc[category] += count;
      });
      return acc;
    }, {})
  ).length;

  return (
    <div className="stats-container">
      <h1>Statistiques des Leads</h1>
      
      <StatSection title="Vue d'ensemble Aujourd'hui">
        <StatCard title="Total des Leads" value={totalLeads} icon="📊" />
        <StatCard title="Leads Contactés" value={leadsContactedToday} icon="📞" />
        <StatCard title="Rendez-vous Obtenus" value="N/A" icon="🤝" />
        <StatCard title="Campagnes Actives" value={activeCampaigns} icon="🚀" />
      </StatSection>

      <StatSection title="Leads Disponibles">
        <StatCard 
          title="Total Disponible" 
          value={totalAvailable} 
          icon="🎯" 
          additionalInfo={`${uniqueAvailableLeads} uniques`}
        />
        {tools.map((tool) => (
          <div 
            key={tool.id}
            onMouseEnter={() => setHoveredToolAvailable(tool.name)}
            onMouseLeave={() => setHoveredToolAvailable(null)}
          >
            <StatCard 
              title={tool.name} 
              value={leadsAvailable[tool.name] || 0} 
              icon={tool.icon} 
              categories={categoriesAvailable[tool.name]}
              showCategories={hoveredToolAvailable === tool.name}
            />
          </div>
        ))}
      </StatSection>

      <StatSection title="Leads Contactés Aujourd'hui">
        <StatCard 
          title="Total Contacté" 
          value={leadsContactedToday} 
          icon="📞" 
        />
        {tools.map((tool) => (
          <div 
            key={tool.id}
            onMouseEnter={() => setHoveredToolContacted(tool.name)}
            onMouseLeave={() => setHoveredToolContacted(null)}
          >
            <StatCard 
              title={tool.name} 
              value={leadsContactedByTool[tool.name] || 0} 
              icon={tool.icon} 
              categories={leadsContactedCategoriesByTool[tool.name]}
              showCategories={hoveredToolContacted === tool.name}
            />
          </div>
        ))}
      </StatSection>

      <StatSection title="Campagnes Créées Aujourd'hui">
        <StatCard 
          title="Total Campagnes" 
          value={activeCampaigns} 
          icon="🚀" 
        />
        {tools.map((tool) => (
          <div 
            key={tool.id}
            onMouseEnter={() => setHoveredToolCampaigns(tool.name)}
            onMouseLeave={() => setHoveredToolCampaigns(null)}
          >
            <StatCard 
              title={tool.name} 
              value={campaignsCreatedByTool[tool.name] || 0} 
              icon={tool.icon} 
              categories={campaignsDetailsByTool[tool.name]}
              showCategories={hoveredToolCampaigns === tool.name}
            />
          </div>
        ))}
      </StatSection>
    </div>
  );
};

export default StatsTab;