import React from 'react';

const ResultPopup = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>×</button>
        <h2>Résultat de l'exportation</h2>
        <p>{content}</p>
        <button onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

export default ResultPopup;
