import React, { useState } from 'react';
import './CSVImportModal.css';

const CSVImportModal = ({ isOpen, onClose, onImport }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState('');

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedOption && file && language) {
      onImport(selectedOption, file, language);
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>×</button>Impossible de charger les leads. Veuillez réessayer plus tard.
        <h2>Insérer vos leads</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="leadSource">Source des leads:</label>
            <select
              id="leadSource"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              required
            >
              <option value="">Sélectionnez une source</option>
              <option value="SN">Sales Navigator</option>
              <option value="ECF">Entrepreneur Client Financé</option>
              <option value="Autres">Autres</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="language">Langue:</label>
            <select
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              required
            >
              <option value="">Sélectionnez une langue</option>
              <option value="fr">Français</option>
              <option value="en">Anglais</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="csvFile">Fichier CSV:</label>
            <input
              type="file"
              id="csvFile"
              accept=".csv"
              onChange={(e) => setFile(e.target.files[0])}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Importer</button>
        </form>
      </div>
    </div>
  );
};

export default CSVImportModal;
