import React, { useState } from 'react';
import './ExportModal.css';

const ExportModal = ({ isOpen, onClose, onExport, tools }) => {
  const [leadCount, setLeadCount] = useState(0);
  const [selectedTool, setSelectedTool] = useState('');
  const [campaignInfo, setCampaignInfo] = useState('');

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onExport({ leadCount, selectedTool, campaignInfo });
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>×</button>
        <h2>Exporter des leads</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="leadCount">Combien de leads souhaitez-vous exporter ?</label>
            <input
              type="number"
              id="leadCount"
              value={leadCount}
              onChange={(e) => setLeadCount(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="tool">Quel est le pistolet que vous souhaitez utiliser pour ce shoot ?</label>
            <select
              id="tool"
              value={selectedTool}
              onChange={(e) => setSelectedTool(e.target.value)}
              required
            >
              <option value="">Sélectionnez un outil</option>
              {tools.map((tool) => (
                <option key={tool.id} value={tool.name}>{tool.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="campaignInfo">Quel est la campagne id/le contenu, id_stratégies les infos nécessaires à une identification ?</label>
            <textarea
              id="campaignInfo"
              value={campaignInfo}
              onChange={(e) => setCampaignInfo(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Récupérer les leads</button>
        </form>
      </div>
    </div>
  );
};

export default ExportModal;