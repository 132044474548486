import React, { useState, useEffect } from 'react';
import './ModernLeadDashboard.css';
import CSVImportModal from './CSVImportModal';
import ImportResultModal from './ImportResultModal';
import ExportModal from './ExportModal';
import ResultPopup from './ResultPopup';
import LeadsTab from './LeadsTab';
import StatsTab from './StatsTab';

const ModernLeadDashboard = ({ apiUrl }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('leads');
  const [successMessage, setSuccessMessage] = useState(null);
  const [importResults, setImportResults] = useState(null);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isResultPopupOpen, setIsResultPopupOpen] = useState(false);
  const [resultPopupContent, setResultPopupContent] = useState('');
  const [stats, setStats] = useState({});
  const [tools, setTools] = useState([]);

  useEffect(() => {
    fetchLeads();
    fetchStats();
    fetchTools();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await fetch(`${apiUrl}/leads`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setLeads(data);
      setIsLoading(false);
    } catch (e) {
      console.error("Error fetching leads:", e);
      setError("Unable to load leads. Please try again later.");
      setIsLoading(false);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await fetch(`${apiUrl}/stats`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setStats(data);
    } catch (e) {
      console.error("Error fetching stats:", e);
    }
  };

  const fetchTools = async () => {
    try {
      const response = await fetch(`${apiUrl}/tools`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTools(data);
    } catch (e) {
      console.error("Error fetching tools:", e);
    }
  };

  const handleImport = (source, file, language) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('source', source);
    formData.append('language', language);

    setIsLoading(true);

    fetch(`${apiUrl}/import-csv`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        if (data.error) {
          setError(`Erreur lors de l'importation: ${data.error}`);
          setSuccessMessage(null);
        } else {
          setSuccessMessage('CSV importé avec succès');
          setError(null);
        }
        setImportResults(data);
        setIsResultModalOpen(true);
        fetchLeads();
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error importing CSV:', error);
        setError(`Erreur lors de l'importation: ${error.message}`);
        setSuccessMessage(null);
      });
  };

  const handleExport = async (exportData) => {
    try {
      const response = await fetch(`${apiUrl}/export-leads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(exportData),
      });
      const data = await response.json();
      
      if (data.count === 0) {
        setResultPopupContent("Aucun lead n'est éligible à cette stratégie.");
      } else {
        if (data.csv) {
          // Create a Blob from the CSV string
          const blob = new Blob([data.csv], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'exported_leads.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        
        if (data.count >= exportData.leadCount) {
          setResultPopupContent(`Vous avez récupéré ${data.count} leads pour le pistolet ${exportData.selectedTool}.`);
        } else {
          setResultPopupContent(`${data.count} leads exportés avec succès pour le pistolet ${exportData.selectedTool}.`);
        }
      }
      setIsResultPopupOpen(true);
      setIsExportModalOpen(false);
    } catch (error) {
      console.error('Error exporting leads:', error);
      setResultPopupContent(`Erreur lors de l'exportation: ${error.message}`);
      setIsResultPopupOpen(true);
    }
  };

  const handleTriEmails = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/tri-emails`, { method: 'POST' });
      const data = await response.json();
      setSuccessMessage(data.message);
      setError(null);
    } catch (error) {
      console.error('Error sorting emails:', error);
      setError(`Erreur lors du tri des emails: ${error.message}`);
      setSuccessMessage(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTreatEmails = async () => {
    if (!window.confirm("Êtes-vous sûr de vouloir traiter les emails ?")) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/treat-emails`, { method: 'POST' });
      const data = await response.json();
      setSuccessMessage(data.message);
      setError(null);
    } catch (error) {
      console.error('Error treating emails:', error);
      setError(`Erreur lors du traitement des emails: ${error.message}`);
      setSuccessMessage(null);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Chargement des leads...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="dashboard">
      <h1 className="dashboard-title">Tableau de Bord - Gestion des Leads</h1>
      {successMessage && <div className="success-message">{successMessage}</div>}
      {error && <div className="error-message">{error}</div>}
      
      <div className="tab-menu">
        <button 
          className={`tab-button ${activeTab === 'leads' ? 'active' : ''}`}
          onClick={() => setActiveTab('leads')}
        >
          Leads
        </button>
        <button 
          className={`tab-button ${activeTab === 'stats' ? 'active' : ''}`}
          onClick={() => setActiveTab('stats')}
        >
          Statistiques
        </button>
      </div>

      <button onClick={handleTriEmails} disabled={isLoading}>
        Trier les Emails
      </button>
      <button onClick={handleTreatEmails} disabled={isLoading}>
        Traiter les Emails
      </button>

      {activeTab === 'leads' ? (
        <LeadsTab
          stats={stats}
          setIsModalOpen={setIsModalOpen}
          setIsExportModalOpen={setIsExportModalOpen}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          leads={leads}
        />
      ) : (
        <StatsTab leads={leads} stats={stats} tools={tools} />
      )}

      <CSVImportModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onImport={handleImport} />
      <ImportResultModal
        isOpen={isResultModalOpen}
        onClose={() => setIsResultModalOpen(false)}
        results={importResults}
      />
      <ExportModal 
        isOpen={isExportModalOpen} 
        onClose={() => setIsExportModalOpen(false)} 
        onExport={handleExport}
        tools={tools}
      />
      <ResultPopup
        isOpen={isResultPopupOpen}
        onClose={() => setIsResultPopupOpen(false)}
        content={resultPopupContent}
      />
      {isLoading && <div className="loading-spinner">Importing...</div>}
    </div>
  );
};

export default ModernLeadDashboard;