import React from 'react';

const Card = ({ title, value, icon }) => (
  <div className="card">
    <div className="card-icon">{icon}</div>
    <div className="card-content">
      <h3>{title}</h3>
      <p className="card-value">{value}</p>
    </div>
  </div>
);

const LeadsTab = ({ stats, setIsModalOpen, setIsExportModalOpen, searchTerm, setSearchTerm, leads }) => {
  return (
    <>
      <div className="card-container">
        <Card title="Total Leads" value={stats.total_leads || 0} icon="📊" />
        <Card title="Leads contactés Aujourd'hui" value={stats.exported_today || 0} icon="🔥" />
        <Card title="Nombre de Rdvs" value="N/A" icon="📈" />
        <Card title="Campagnes actives aujourd'hui" value={stats.unique_campaigns_today || 0} icon="🚀" />
      </div>

      <div className="action-bar">
        <div className="action-buttons">
          <button className="btn btn-primary" onClick={() => setIsModalOpen(true)}>Importer CSV</button>
          <button className="btn btn-secondary" onClick={() => setIsExportModalOpen(true)}>Exporter Leads</button>
        </div>
        <input
          type="text"
          placeholder="Rechercher par nom, email ou entreprise..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="table-container">
        <table className="lead-table">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Société</th>
              <th>Date de Création</th>
              <th>Source</th>
              <th>Catégorie</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead) => (
              <tr key={lead.id}>
                <td>{lead.nom}</td>
                <td>{lead.prenom}</td>
                <td>{lead.societe}</td>
                <td>{lead.dateCreation}</td>
                <td>{lead.source}</td>
                <td>{lead.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LeadsTab;
